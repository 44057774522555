.link-button {
  color: #4b97de !important;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline;
  margin: 0;
  padding: 0;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
  text-align: left;
  outline:none;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

.link-button:hover {
  color: #40a9ff;
}

.link-button:active {
  color: #096dd9;
}